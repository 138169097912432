import React from "react"
import ThankYouPage from "../../components/ThankYou/ThankYouPage"
import { Box } from "@chakra-ui/core"
import SEO from "../../components/SEO"
import Layout from "../../components/Layout"

const Register = () => {
  return (
    <Box>
      <SEO title="நன்றி" />
      <Layout>
        <ThankYouPage />
      </Layout>
    </Box>
  )
}

export default Register
