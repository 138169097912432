import React from "react"
import { Flex, Box, Heading, Text, PseudoBox } from "@chakra-ui/core"
import AnimatedTickmark from "../AnimatedTickmark"
import { Link } from "gatsby"

const ThankYouPage = (): JSX.Element => {
  return (
    <Box>
      <Flex
        minHeight="50vh"
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <AnimatedTickmark />
        <Box textAlign="center" mx="auto">
          <Box>
            <Heading size="xl">நன்றி</Heading>
            <Text>நாங்கள் விரைவில் உங்களுடன் தொடர்பு கொள்வோம்.</Text>
            <Link to="/">
              <PseudoBox
                mt={2}
                color="#0D44A6"
                _hover={{ textDecoration: "underline" }}
                fontWeight="bold"
              >
                முகப்பு
              </PseudoBox>
            </Link>
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default ThankYouPage
